@font-face {
    font-family: 'tramdot';
    src: url('jgs_tramdot.woff') format('woff');
}

@font-face {
    font-family: 'tramdot_display';
    src: url('jgs_tramdot_display.woff') format('woff');
}

:root {
    --color-yellow: #fff200;
    --color-blue: #3fa9f5;
    --color-white: white;
    --color-orange: #f37421;
    --color-green: #baff00;
    --color-pink: #ff6990;
    --color-greendam: #04b054;
    --color-purple: #5903ff;
    --stroke-width: 0.3rem;
    --svg-font: 10rem;
}

svg.filter {
    position: fixed;
    opacity: 0;
}

* {
    font-family: 'tramdot';
    text-rendering: geometricPrecision;
    font-kerning: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: visible;
    position: relative;
}

button {
    cursor: pointer;
}

body {
    background-color: var(--color-green);
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    font-size: 10px;
    overflow-x: hidden;
}

#root {
    padding: 1rem;
}

#root > * {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
}

main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

input,
.buttonsection button,
.bgcolorsection button {
    font-size: 8rem;
    width: 100%;
    padding: 5px;
    text-align: center;
    height: 1.5em;
    filter: url('#glitch');
    background-color: white;
    line-height: 0px;
    border: none;
    z-index: 2;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F37421FF' stroke-width='8' stroke-dasharray='20%2c 20' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
}

input * {
    background-color: white;
}

input::placeholder {
    font-size: 5rem;
}

section {
    width: 100%;
    min-height: 400px;
    min-width: 400px;
    z-index: 5;
    pointer-events: none;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: start;
}

.artwork svg {
    position: absolute;
    font-family: 'tramdot_display';
    font-size: var(--svg-font);
}

.artwork svg * {
    font-family: 'tramdot_display';
}

/* svg stroke effect */

.stroke_default {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: var(--stroke-width);
    stroke: var(--color-orange);
}
.artwork svg :is(text, path:not(.solid)) {
    fill: transparent;
}
.artwork svg :is(text, path:not(.solid)):nth-child(1) {
    stroke: var(--color-yellow);
    stroke-width: calc(var(--stroke-width) * 6);
}
.artwork svg :is(text, path:not(.solid)):nth-child(2) {
    stroke: var(--color-blue);
    stroke-width: calc(var(--stroke-width) * 4);
}
.artwork svg :is(text, path:not(.solid)):nth-child(3) {
    stroke-width: calc(var(--stroke-width) * 2);
}
.artwork svg :is(text, path:not(.solid)):nth-child(4) {
    stroke-width: 0px;
    fill: var(--color-white);
}
/* artwork */
.artwork {
    width: 1px;
    height: 1px;
    left: 50%;
    top: 50%;
    position: absolute;
}

/* head */
svg.head {
    z-index: 10;
}

/* body */
svg.body {
    transform: translate(-50%);
}

/* buttonsection */
.buttonsection {
    display: flex;
    align-items: end;
    gap: 1rem;
    z-index: 6;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 4rem;
}

.buttonsection .button {
    width: 50%;
    height: 100%;
}

.buttonsection .button::after,
.bgcolorsection .button::after,
.input::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: white;
}

.input::after {
    width: 95%;
    height: 95%;
}

.bgcolorsection .button::after {
    width: 80%;
    height: 80%;
}

.buttonsection button {
    color: black;
    font-size: 2rem;
    height: 100%;
    line-height: 0.8em;
}

/* bgcolorsection */
.bgcolorsection {
    z-index: 6;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 4.5rem;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.bgcolorsection .button {
    flex-grow: 2;
}

.bgcolorsection .button button {
    width: 100%;
    height: 100%;
}

.bgcolorsection .green button,
.bgcolorsection .green.button::after {
    background-color: var(--color-green);
}

.bgcolorsection .pink button,
.bgcolorsection .pink.button::after {
    background-color: var(--color-pink);
}

.bgcolorsection .blue button,
.bgcolorsection .blue.button::after {
    background-color: var(--color-blue);
}

.bgcolorsection .greendam button,
.bgcolorsection .greendam.button::after {
    background-color: var(--color-greendam);
}

.bgcolorsection .purple button,
.bgcolorsection .purple.button::after {
    background-color: var(--color-purple);
}

.bgcolorsection .orange button,
.bgcolorsection .orange.button::after {
    background-color: var(--color-orange);
}

/* logo */
section .logo {
    position: relative;
    width: 8rem;
    fill: var(--color-orange);
}

section .logo.white {
    fill: white;
}

.sectionwrapper {
    top: 3rem;
}

.imagewrapper {
    position: absolute;
    position: fixed;
    left: -100000px;
    top: -10000px;
}

.canvasimage {
    height: 0px;
}

.canvasimage.open {
    height: unset;
}

.canvasimage.open img {
    margin-top: 7rem;
    margin-bottom: 7rem;
    border: solid var(--color-orange) 3px;
    max-width: 100%;
}
